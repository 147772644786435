/* pages/ContactMe.css */
.contact-page h1 {
  font-weight: 500;
  letter-spacing: 0.0375rem;
}

.social-media-links a:hover {
  opacity: 0.7;
}

.contact-info a {
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  
  .text-gradient {
    background: linear-gradient(45deg, #2563eb, #3b82f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .stat-box {
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.8);
  }
  
  .stat-box:hover {
    transform: translateY(-5px);
    background: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  }