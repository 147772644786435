/* App.css - Fixed footer styling */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

body {
  font-family: "Roboto Mono", "Helvetica Neue";
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding-bottom: 60px; /* Space for footer */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo {
  width: 3.5rem;
}

nav a.nav-link {
  font-weight: 500;
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #282c34 !important;
  border-top: 0.1875rem solid transparent;
  transition: all 0.3s ease;
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-top: 0.1875rem solid #282c34;
}

.navbar .navbar-nav .nav-link:hover {
  background-color: #61dafb;
  color: #000000 !important;
}

.footer {
  background-color: #f8f9fa;
  padding: 1rem 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e7e7e7;
}

/* Header styles */
.header-wrapper {
  background-color: #f8f9fa;
  padding: 1rem 0;
  border-bottom: 1px solid #e7e7e7;
}

:root {
  --primary-blue: #2563eb;
  --dark-gray: #1f2937;
  --light-gray: #f3f4f6;
  --accent-blue: #3b82f6;
  --hover-blue: #1d4ed8;
}

/* Modern Glassmorphism Effect */
.glassmorphism {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
}

/* Animated Grid Background */
.header-wrapper {
  --grid-size: 60px;
  --grid-color: rgba(59, 131, 246, 0.199);
  position: relative;
}

.animated-grid-bg {
  position: relative;
  background: linear-gradient(-45deg, #f3f4f6, #e5e7eb, #dbeafe, #bfdbfe);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.animated-grid-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(var(--grid-color) 1px, transparent 1px),
    linear-gradient(90deg, var(--grid-color) 1px, transparent 1px),
    linear-gradient(45deg, var(--grid-color) 1px, transparent 1px),
    linear-gradient(-45deg, var(--grid-color) 1px, transparent 1px);
  background-size: 
    var(--grid-size) var(--grid-size),
    var(--grid-size) var(--grid-size),
    calc(var(--grid-size) * 2) calc(var(--grid-size) * 2),
    calc(var(--grid-size) * 2) calc(var(--grid-size) * 2);
  animation: grid-move 20s linear infinite;
  opacity: 0.5;
}

@keyframes grid-move {
  0% { background-position: 0 0, 0 0, 0 0, 0 0; }
  100% { background-position: 
    var(--grid-size) var(--grid-size), 
    var(--grid-size) var(--grid-size), 
    calc(var(--grid-size) * 4) calc(var(--grid-size) * 4), 
    calc(var(--grid-size) * -4) calc(var(--grid-size) * -4); 
  }
}

/* Glow Effect */
.glow-on-hover {
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.glow-on-hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  transition: 0.5s;
}

.glow-on-hover:hover::before {
  left: 100%;
}

.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
}

.text-gradient {
  background: linear-gradient(45deg, #2563eb, #3b82f6, #60a5fa);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-pulse 4s ease infinite;
}

@keyframes gradient-pulse {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Modern Card Design */
.card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-hover:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Animated Underline Effect */
.underline-animation {
  position: relative;
  padding-bottom: 3px;
}

.underline-animation::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--primary-blue);
  transition: width 0.3s ease;
}

.underline-animation:hover::after {
  width: 100%;
}

.hover-primary:hover {
  color: var(--primary-blue) !important;
  transform: translateY(-3px);
  transition: all 0.3s ease;
}