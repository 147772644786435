.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 1.5rem;
  margin: 2rem 0;
}

.expertise-card {
  padding: 1.5rem;
  border-radius: 1rem;
  text-align: center;
  transition: all 0.3s ease;
}

.video-container {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.video-container:hover {
  transform: translateY(-5px);
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: 2px solid rgba(59, 130, 246, 0.1);
  border-radius: 0.75rem;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.stat-box {
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 1rem;
  text-align: center;
  transition: all 0.3s ease;
}

.stat-box:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.profession-badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  background: rgba(59, 130, 246, 0.1);
  color: var(--primary-blue);
  font-size: 0.9rem;
  margin: 0.25rem;
  transition: all 0.3s ease;
}

.profession-badge:hover {
  background: var(--primary-blue);
  color: white;
}