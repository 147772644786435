.privacy-policy {
  margin: 2rem auto;
  max-width: 800px;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 1rem;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.05);
  backdrop-filter: blur(10px);
}

.privacy-policy h1 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--dark-gray);
  background: linear-gradient(45deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.privacy-policy h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem 0 1rem;
  color: var(--dark-gray);
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--light-gray);
}

.privacy-policy p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--dark-gray);
  margin-bottom: 1.5rem;
}

.privacy-policy ul {
  list-style: none;
  padding-left: 1.5rem;
  margin: 1.5rem 0;
}

.privacy-policy li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.8rem;
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--dark-gray);
}

.privacy-policy li:before {
  content: '•';
  position: absolute;
  left: 22%;
  color: var(--primary-blue);
  font-weight: 700;
}

.privacy-policy a {
  color: var(--primary-blue);
  text-decoration: none;
  transition: all 0.3s ease;
}

.privacy-policy a:hover {
  color: var(--hover-blue);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: 2rem 1.5rem;
    margin: 1rem;
  }
  
  .privacy-policy h1 {
    font-size: 2rem;
  }
}